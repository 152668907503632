import React from "react"
import Layout from "../components/layout"
import Prices from "../components/Prices"
import Seo from "../components/seo"

const Pricing = ({ location }) => {
  let title = null
  location?.state?.title === undefined
    ? (title = null)
    : (title = location.state.title)

  return (
    <>
      <Layout>
        <Seo title="Cennik" />
        {title ? <Prices defaultService={title} /> : <Prices />}
      </Layout>
    </>
  )
}

export default Pricing
