import { Link } from "gatsby"
import React, { useCallback, useRef } from "react"
import styled from "styled-components"

const PricingTag = ({ id, title, services, setServices, className }) => {
  const pricingTagRef = useRef(null)

  const handleClick = useCallback(() => {
    const tagValue = pricingTagRef.current.innerText

    const filteredServices = services.filter(service => {
      const serviceCategory = service.node.serviceCategory.categoryName
      return serviceCategory === tagValue
    })
    if (id.toLowerCase() !== "Show All".toLowerCase()) {
      setServices(filteredServices)
    } else {
      setServices(services)
    }
  }, [services, setServices, id])

  return (
    <PrincingTagItem
      ref={pricingTagRef}
      onClick={handleClick}
      to="#pricinglist"
      className={className}
    >
      {title}
    </PrincingTagItem>
  )
}

export default PricingTag

const PrincingTagItem = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  word-wrap: break-word;
  text-decoration: none;
  color: black;
  background-color: white;
  border-radius: 12px;
  box-shadow: 1px 1px 12px rgba(230, 120, 100, 0.4);
  transition: 0.2s;
  padding: 2px;
  font-size: clamp(1rem, 3vw, 1.2rem);
  font-family: "Gentium Book Basic";
  font-style: italic;
  text-align: center;
  text-shadow: 1px 1px 2px #aaa;
  min-height:5vh;

  &:hover {
    transform: scale(1.05);
    box-shadow: 1px 1px 12px rgba(230, 120, 100, 0.7);
  }
  &.show-all {
    grid-column-end: span 6;
    min-height: 5vh;
    @media screen and (max-width: 1200px) {
      grid-column-end: span 3;
    }
    @media screen and (max-width: 768px) {
      grid-column-end: span 2;
    }
  }
`
