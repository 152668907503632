import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PricingTag from "./PricingTag"
import { useStaticQuery, graphql } from "gatsby"

const Prices = props => {
  const [selectedServices, setSelectedServices] = useState([])
  const servicesData = useStaticQuery(graphql`
    query servicesQuery {
      allDatoCmsService(sort: { fields: position }) {
        edges {
          node {
            serviceName
            servicePrice
            serviceCategory {
              categoryName
            }
            id
          }
        }
      }
      allDatoCmsServiceCategory(sort: { fields: position, order: ASC }) {
        edges {
          node {
            id
            categoryName
          }
        }
      }
    }
  `)

  const servicesArray = servicesData.allDatoCmsService.edges
  const servicesCategories = servicesData.allDatoCmsServiceCategory.edges

  const defaultService = () => {
    if (props.defaultService) {
      const filteredServices = servicesArray.filter(service => {
        const serviceCategory = service.node.serviceCategory.categoryName
        return serviceCategory === props.defaultService
      })
      setSelectedServices(filteredServices)
    } else {
      setSelectedServices(servicesArray)
    }
  }

  useEffect(() => {
    defaultService()
  }, [])

  const displayServices = selectedServices.map(service => {
    const serviceName = service.node.serviceName
    const servicePrice = service.node.servicePrice
    const id = service.node.id
    return (
      <PricingListItem key={id}>
        <div style={{ width: "85%" }}>{serviceName}</div>
        <div style={{ width: "15%", textAlign: "right" }}>{servicePrice}zł</div>
      </PricingListItem>
    )
  })

  const displayTags = servicesCategories.map(item => {
    const title = item.node.categoryName
    const id = item.node.id
    return (
      <PricingTag
        key={id}
        id={id}
        title={title}
        services={servicesArray}
        setServices={setSelectedServices}
        to="#pricinglist"
      ></PricingTag>
    )
  })

  return (
    <PricingWrapper>
      <PricingTagWrapper tagsCount={displayTags.length} id="pricinglist">
        {displayTags}
        <PricingTag
          key="show-all-pricing-tag"
          className="show-all"
          id="show all"
          title={"Pokaż Wszystkie".toUpperCase()}
          services={servicesArray}
          setServices={setSelectedServices}
        ></PricingTag>
      </PricingTagWrapper>
      <PricingList>
        <PricingListItem>
          <h4>Nazwa Usługi</h4>
          <h4>Cena Usługi</h4>
        </PricingListItem>
        {displayServices}
      </PricingList>
    </PricingWrapper>
  )
}

export default Prices

const PricingWrapper = styled.div`
  min-height: calc(100vh - 81px - 5vh);
  width: 100%;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 1200px) {
    gap: 10px;
  }
`

const PricingTagWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(${props => props.tagsCount / 6}, 10vh);
  gap: 15px;
  width: 90%;
  z-index: 2;

  @media screen and (max-width: 1200px) {
    width: 90vw;
    gap: 10px;
    grid-template-columns: repeat(3, 28vw);
    grid-template-rows: repeat(${props => Math.ceil(props.tagsCount / 3)}, 5vh);
  }
  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 45vw);
    grid-template-rows: repeat(${props => Math.ceil(props.tagsCount / 2)}, 6vh);
  }
`

const PricingList = styled.div`
  width: 90%;
  height: 70vh;
  overflow: auto;
  z-index: 1;
  border-radius: 12px;
  box-shadow: 1px 1px 12px rgba(230, 120, 100, 0.4);
  @media screen and (max-width: 1200px) {
    height: 90vh;
    width: 90vw;
  }
`
const PricingListItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  font-size: 1.2rem;
  width: 100%;
  gap: 10px;
  word-break: break-all;
  font-family: "Gentium Book Basic";
  font-style: italic;

  &:nth-child(2n + 1) {
    background-color: #faebe6;
  }
  &:first-child {
    background-color: #efd3cf;
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    font-weight: bold;
    &:hover{
      background-color: #efd3cf;
    }
  }
  &:hover{
    background-color:#e3a79e;
  }
`
